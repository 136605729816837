 
 
 export const routeConstants = {
     all: '*',
     home: '/',
     login: 'log-in',
     dashboards: 'dashboards',
     forgotPassword: 'forgot-password',
     resetPassword: 'reset-password',
     generalDashboard: 'general-dashboard',
     programDashboard: 'program-dashboard',
     programs: 'programs',
     pendingPrograms: 'pending-programs',
     beneficiaries: 'beneficiaries',
     survey: 'survey',
     surveys: 'surveys',
     mda: 'mda',
     dataAudit: 'data-audit',
     multiBeneficiaries: 'multi-beneficiaries',
     dataCleanup: 'data-cleanup',
     reviewUploads: 'review-uploads',


     register: 'register',
     terms: 'terms',
     faq: 'faq',
     unauthorized: 'unauthorized',
     deactivated: 'deactivated',

     admin: 'admin',
     systemAdmin: 'system-admin',
     mdaManager: 'mda-manager',
     mdaAdmin: 'mda-admin',
     programManager: 'program-manager',
     stateProgramManager: 'state-program-manager',
     agent: 'agent',

     userManagement: 'user-management',
     profile: 'profile',
     appSettings: 'app-settingst',

     userLevels: {
        systemAdmin: 'SYSTEMADMIN',
        mdaAdmin: 'MDAADMIN',
        mdaManager: 'MDAUSER',
        agent: 'AGENT',
     },
     userLevelIds: {
        systemAdmin: 1,
        mdaAdmin: 5,
        mdaManager: 2,
        agent: 4,
     },
     userLevelKeys: [
        {
            name: 'SYSTEMADMIN',
            id: 1
        },
        {
            name: 'MDAADMIN',
            id: 5
        },
        {
            name: 'MDAUSER',
            id: 2
        },
        {
            name: 'AGENT',
            id: 4
        },
     ],
 };
