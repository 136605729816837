
const LOGIN = 'LOGIN';
const GETSESSIONDATA = 'GETSESSIONDATA';
const LOGOUT = 'LOGOUT';

const SETVERSION = 'SETVERSION';
const CLEARVERSION = 'CLEARVERSION';

const SETPROGRAMLIST = 'SETPROGRAMLIST';
const SETMDALIST = 'SETMDALIST';
const SETCATEGORYLIST = 'SETCATEGORYLIST';

// const FACILITATORCOUNT = 'FACILITATORCOUNT';
// const FACILITATORBYPILLARS = 'FACILITATORBYPILLARS';
// const FACILITATORTOPTENSTATES = 'FACILITATORTOPTENSTATES';
// const FACILITATORTOPTENLGAS = 'FACILITATORTOPTENLGAS';
// const FACILITATOGENDERDISTRIBUTION = 'FACILITATOGENDERDISTRIBUTION';
// const FACILITATORAGEDISTRIBUTION = 'FACILITATORAGEDISTRIBUTION';
// const FACILITATOREDUCATIONALLEVEL = 'FACILITATOREDUCATIONALLEVEL';
// const FACILITATORAPPLIEDCOUNT = 'FACILITATORAPPLIEDCOUNT';
// const FACILITATORAPPLIEDCOUNT = 'FACILITATORAPPLIEDCOUNT';

export {
    LOGIN,
    GETSESSIONDATA,
    LOGOUT,
    SETVERSION,
    CLEARVERSION,
    SETPROGRAMLIST,
    SETMDALIST,
    SETCATEGORYLIST,
}