import { SETPROGRAMLIST, SETMDALIST, SETCATEGORYLIST } from "../constants/action-constants";

const initialState = {
    programs: [],
    mdas: [],
    categories: [],
}

const utilityReducer = (state: any = initialState, action: any) => {
    switch(action.type) {
        case SETPROGRAMLIST:
            return {...state, programs: action.payload};
        case SETMDALIST:
            return {...state, mdas: action.payload};
        case SETCATEGORYLIST:
            return {...state, categories: action.payload};
        default:
            return state;
    }
}

export default utilityReducer;